<template>
    <div class="letsgo-container" :style="{'height': __layoutCenterHeightCalculate.value - 20 +'px'}">
        <!-- component Let's go-->
        <components-let-s-go
            :header-title="__isNull(response.experience) ? null : response.experience.name"
            :title="$tc(`adventure.${$route.params.type === 'tasks' ? 'task' : $route.params.type}`, 1)"
            :busy-on="busyOn"
            :show-no-hits-message="showNoHitsMessage"
            :button-next-disabled="isNextDisabled"
            @eventPrevious="eventPrevious"
            @eventNext="eventNext"
        >
            <!-- content experience -->
            <template #content>
                <!-- content experience: story, mission -->
                <div v-if="!isShowTask" class="mx-1 editor-text border"
                     v-html="__isNull(response.experience)? null : response.experience[$route.params.type]"/>
                <!-- content experience: tasks -->
                <div v-if="isShowTask" class="d-flex flex-wrap justify-content-start">
                    <div v-for="(experience, index) in getExperience" class="mx-1 mb-2">
                        <b-card
                            :header="$tc('adventure.task', 0) +' '+ (index + 1)"
                            header-text-variant="white"
                            header-tag="header"
                            header-class="text-center"
                            :header-bg-variant="experience.solved ? 'secondary' :'primary'"
                            footer-bg-variant="primary"
                            class="text-left"
                            style="width: 175px; height: 250px; cursor: pointer"
                            border-variant="secondary"
                            @click="gotoTask(experience.key)"
                        >
                            <div class="overflow-auto" style="max-height: 100%">
                                <div class="text-center">{{experience.task.title}}</div>
                                <!-- solved -->
                                <div v-if="experience.solved === true"
                                     class="d-flex justify-content-center align-items-center border"
                                     style="height: 50px"
                                >
                                    <b-icon-patch-check-fill
                                        variant="secondary"
                                        scale="3"
                                    />
                                </div>
                                <div v-else-if="experience.solved === false"
                                     class="d-flex justify-content-center align-items-center border"
                                     style="height: 50px"
                                >
                                    <!-- solved failed -->
                                    <b-icon-emoji-frown-fill
                                        variant="danger"
                                        scale="3"
                                    />
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
            </template>

            <!-- content: help -->
            <template #help>
                <div v-html="$t(`adventure.help_content.${$route.params.type}`)"></div>

                <div class="text-center mt-3 mb-2" v-show="isShowTask">
                    <b-icon-hand-thumbs-up-fill scale="2.0" variant="danger"></b-icon-hand-thumbs-up-fill>
                </div>
            </template>

        </components-let-s-go>
    </div>
</template>

<script>
import ComponentsLetSGo from "@/components/_public/let-s-go/elements/ComponentLet-s-go";

export default {
    name: "LetsGoExperience",
    components: {ComponentsLetSGo},
    props: {},
    data() {
        return {
            busyOn: false,
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                getExperience: `api/v1/let-s-go/experience`,
            },
            endpoints: ['story', 'mission', 'tasks'],
            showNoHitsMessage: false,
            response: [],
        }
    },
    mounted() {
        this.loadDataFromBase();
    },
    methods: {
        // <-- events -->
        eventPrevious() {
            let index = this.endpoints.indexOf(this.$route.params.type);
            let endpoints = this.endpoints.slice(0, index);
            endpoints.reverse();
            // <-- endpoint -->
            let endpoint = endpoints.find((n) => {
                if (!this.__isNull(this.response.experience[n])) {
                    return n;
                }
            });
            // <-- goto -->
            let url = `/let-s-go`
                + '/' + this.$route.params.route
                + '/' + this.$route.params.destination;
            if (this.$route.params.type != 'tasks') {
                url += (this.__isNull(endpoint) ? '' : '/' + this.$route.params.experience + '/' + endpoint);
            }
            this.$router.push(url).then();
        },
        eventNext() {
            let index = this.endpoints.indexOf(this.$route.params.type);
            let endpoints = this.endpoints.slice(index + 1);

            // <-- endpoint -->
            let endpoint = endpoints.find((n) => {
                if (!this.__isNull(this.response.experience[n])) {
                    return n;
                }
            });
            // <-- goto -->
            this.$router.push(`/let-s-go`
                + '/' + this.$route.params.route
                + '/' + this.$route.params.destination
                + ('/' + this.$route.params.experience + '/' + endpoint)
            ).then();
        },
        // <-- tasks -->
        gotoTask(key) {
            this.$router.push(this.$route.fullPath + '/' + key).then();
        },
        // <-- base -->
        /**
         * @vuese
         * Load destinations from database
         */
        loadDataFromBase() {
            this.busyOn = true;
            this.showNoHitsMessage = false;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getExperience
                + `/${this.$route.params.route}`
                + `/${this.$route.params.experience}`,
                config)
                .then((response) => {
                    if (this.__isNull(response.data)) {
                        this.showNoHitsMessage = true;
                        this.busyOn = false;
                        return;
                    }
                    this.$set(this, 'response', response.data);
                    this.busyOn = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        isNextDisabled() {
            let index = this.endpoints.indexOf(this.$route.params.type);
            index += 1;
            let nextEndpoint = this.endpoints[index];
            // <--  -->
            if (index >= this.endpoints.length) {
                return true;
            }
            // <--  -->
            return this.__isNull(this.response.experience) || this.__isNull(this.response.experience[nextEndpoint]);
        },
        isShowTask() {
            return this.$route.params.type === 'tasks';
        },
        getExperience() {
            let experienceTasks = {};
            // <--  -->
            if (this.__isNull(this.response.experience)) {
                return experienceTasks;
            }
            // <--  -->
            experienceTasks = this.response.experience[this.$route.params.type];
            // <-- mark if experienceTask is solved -->
            experienceTasks.forEach(experienceTask => {
                experienceTask.solved = null;
                this.response.taskSolutions.forEach(taskSolution => {
                    if (experienceTask.key === taskSolution.task_key) {
                        experienceTask.solved = experienceTask.solved === null ? false : experienceTask.solved;
                        taskSolution.content.every(v => {
                            if (v.points.points > 0) {
                                experienceTask.solved = true;
                                return false;
                            }
                            return true;
                        });
                    }
                });
            });
            // <--  -->
            // <-- true - solved, false- solved failed, null-not trying yet -->
            return experienceTasks;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
