<template>
    <b-overlay :opacity="overlay.opacity" :show="busyOn || overlay.show" rounded="sm" class="letsgo-comp">
        <b-card
            :header="headerTitle"
            header-text-variant="white"
            header-tag="header"
            header-class="text-center header_style"
            header-bg-variant="secondary"
            footer-class="footer_style"
            footer-bg-variant="primary"
            :title="title"
            class="text-center"
            style="height: 100%;  border-radius: 30px;"
            border-variant="primary"
        >
            <!-- body -->
            <div v-if="!showNoHitsMessage" style="height: 95%; overflow-y: auto">
                <!-- body: content -->
                <slot name="content" :slotProps="slotProps"></slot>
            </div>
            <!-- No hits message -->
            <div v-if="showNoHitsMessage" class="d-flex align-items-center justify-content-center" style="height: 70%">
                <b-alert variant="danger" class="text-uppercase" show>{{$t('form.no_hits')}}</b-alert>
            </div>
            <!-- report -->
            <div v-show="reportVisible" class="report p-4">
                <!-- title -->
                <div class="font-weight-bold">REZULTATI:</div>
                <!-- results -->
                <div class="mt-2 pt-4 overflow-auto"
                     style="background: whitesmoke; height: calc(100% - 80px); border-radius: 25px;">
                    <!-- route name -->
                    <div class="font-weight-bolder">{{reportData.routeName}}</div>
                    <!-- destinations -->
                    <div v-for="destination in reportData.destinations">
                        <div class="mx-2 mt-2 d-flex justify-content-between font-weight-bold"
                             :class="destination.solved.solvedPercent === 100 ? 'text-secondary' : ''"
                        >
                            <span class="text-left" style="white-space: nowrap;">{{destination.name}}</span>
                            <span v-if="detailOn" class="horizontal_dotted_line mx-1"></span>
                            <span v-if="detailOn"
                                  style="white-space: nowrap;">{{destination.solved.solvedPercent.toFixed(0).toLocaleString()}} %</span>
                        </div>
                        <!-- experience -->
                        <div v-for="experience in destination.experiences">
                            <div class="ml-3 mr-2 d-flex justify-content-between"
                                 :class="detailOn ? 'font-weight-bolder' : ''">
                                <span class="text-left" style="white-space: nowrap;">{{experience.name}}</span>
                                <span class="horizontal_dotted_line mx-1"></span>
                                <span style="white-space: nowrap;">{{experience.solved.percent}} %</span>
                            </div>
                            <!-- tasks -->
                            <div v-if="detailOn" v-for="task in experience.tasks">
                                <!-- solved percent -->
                                <div class="ml-4 mr-2 d-flex justify-content-between">
                                    <span class="text-left" style="white-space: nowrap;">{{task.title}}</span>
                                    <span class="horizontal_dotted_line mx-1"></span>
                                    <span style="white-space: nowrap;">
                                    {{task.solvedPercent}} %
                                </span>
                                </div>
                                <!-- failed attempt -->
                                <div v-if="task.failedAttempt > 0"
                                     class="ml-5 mr-2 d-flex justify-content-between text-danger">
                                <span class="text-left"
                                      style="white-space: nowrap;">{{$t('adventure.failed attempt')}}</span>
                                    <span class="horizontal_dotted_line mx-1"></span>
                                    <span style="white-space: nowrap;">
                                    {{task.failedAttempt}}x
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- summary: tasks -->
                    <div v-if="!__isNull(reportData.summary)" class="mb-4">
                        <div class=" mt-4 ml-2 mr-2 d-flex justify-content-between font-weight-bolder"
                             style="font-size: larger">
                            <span class="text-left"
                                  style="white-space: nowrap;">{{$t('adventure.total_tasks_solved')}}</span>
                            <span class="horizontal_summary_dotted_line mx-1"></span>
                            <span
                                style="white-space: nowrap;">{{reportData.summary.percent}} %</span>
                        </div>
                        <div v-show="detailOn" class="ml-2">
                            <!-- summary: tasks -->
                            <div class="ml-2 mr-2 d-flex justify-content-between">
                                <span class="text-left"
                                      style="white-space: nowrap;">{{$t('adventure.number_of_tasks_solved')}}</span>
                                <span class="horizontal_dotted_line mx-1"></span>
                                <span
                                    style="white-space: nowrap;">{{reportData.summary.solvedTasks}} / {{reportData.summary.tasks}}</span>
                                <span style="margin-right: 1rem"> </span>
                            </div>
                            <!-- summary: points -->
                            <div class="ml-2 mr-2 d-flex justify-content-between">
                                <span class="text-left"
                                      style="white-space: nowrap;">{{$t('adventure.number_of_points')}}</span>
                                <span class="horizontal_dotted_line mx-1"></span>
                                <span
                                    style="white-space: nowrap;">{{reportData.summary.solvedPoints}} / {{reportData.summary.maxPoints}}</span>
                                <span style="margin-right: 1rem"> </span>
                            </div>
                            <!-- summary: failed attempts -->
                            <div class="ml-2 mr-2 d-flex justify-content-between">
                                <span class="text-left"
                                      style="white-space: nowrap;">{{$t('adventure.number_of_failed_attempts')}}</span>
                                <span class="horizontal_dotted_line mx-1"></span>
                                <span style="white-space: nowrap;">{{reportData.summary.failedAttempts}}</span>
                                <span style="margin-right: 1rem"> </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- report buttons -->
                <div class="report_buttons">
                    <b-button-toolbar class="p-0 m-0 d-flex justify-content-around">
                        <!-- button show/hide details -->
                        <b-button @click="detailOn = !detailOn">
                            {{detailOn ? $t('form.detail_hide') : $t('form.detail_show')}}
                        </b-button>
                        <!-- button close -->
                        <b-button @click="reportVisible = !reportVisible">{{$t('form.close')}}</b-button>
                    </b-button-toolbar>
                </div>
            </div>
            <!-- help -->
            <b-alert class="help m-0 pb-4 text-secondary text-left" :show="helpVisible" dismissible
                     @dismissed="helpVisible = false;">
                <!-- slot -->
                <slot name="help"></slot>
            </b-alert>
            <!-- footer -->
            <template #footer>
                <!-- Component footer -->
                <slot name="footer">
                    <!-- Toolbar with previous, help and next buttons-->
                    <b-button-toolbar v-resize @resize="layoutFooterResize" class="p-0 m-0 d-flex justify-content-between">
                        <div style="width: 80px">
                            <!-- button previous -->
                            <b-button
                                style="width: 100%"
                                :hidden="buttonPreviousHidden"
                                :disabled="buttonPreviousDisabled"
                                @click="eventPrevious()"
                            >
                                <b-icon-caret-left/>
                            </b-button>
                        </div>
                        <!-- button help -->
                        <b-button variant="info"
                                  :hidden="buttonHelpHidden"
                                  :disabled="buttonHelpDisabled"
                                  v-b-tooltip.hover.v-secondary="$t('form.help')"
                                  @click="helpVisible = !helpVisible"
                        >
                            <b-icon-question-octagon variant="secondary"/>
                        </b-button>
                        <!-- button show/hide map -->
                        <b-button
                            v-if="!buttonToggleMapHidden"
                            pill
                            style="min-width: 200px"
                            class="d-inline-block"
                            @click="eventToggleMap()"
                        >
                            <span v-show="toggleMap"> {{$t('adventure.sider_experience.toggle_map_hide')}}</span>
                            <span v-show="!toggleMap">{{$t('adventure.sider_experience.toggle_map_show')}}</span>
                            <b-icon
                                class="ml-2"
                                :icon="toggleMap ? 'map-fill' : 'map'"
                            ></b-icon>
                        </b-button>
                        <!-- button report -->
                        <b-button variant="info"
                                  :hidden="buttonReportHidden"
                                  :disabled="buttonReportDisabled"
                                  v-b-tooltip.hover.v-secondary="$t('adventure.help_content.report')"
                                  @click="eventReport()"
                        >
                            <b-icon-card-checklist variant="secondary"/>
                        </b-button>
                        <!-- button next -->
                        <div style="width: 80px">
                            <b-button
                                style="width: 100%"
                                :hidden="buttonNextHidden"
                                :disabled="buttonNextDisabled"
                                @click="eventNext()">
                                <b-icon-caret-right/>
                            </b-button>
                        </div>
                    </b-button-toolbar>
                </slot>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>

// @group LETS GO
export default {
    name: "ComponentsLet-s-go",
    components: {},
    props: {
        // Activates busy marker
        busyOn: {
            type: Boolean,
            default: false
        },
        // Header title, usually a route title.
        headerTitle: {
            type: String,
            default: "Let's go!",
        },
        // Component title
        title: {
            type: String,
            default: null,
        },
        // Hide button previous
        buttonPreviousHidden: {
            type: Boolean,
            default: false
        },
        // Disabled button previous
        buttonPreviousDisabled: {
            type: Boolean,
            default: false
        },
        // Hide button help
        buttonHelpHidden: {
            type: Boolean,
            default: false
        },
        // Disabled button help
        buttonHelpDisabled: {
            type: Boolean,
            default: false
        },
        // Component help content in html format
        helpContent: {
            type: String,
            default: 'No help, yet',
        },
        // Hide button toggle map
        buttonToggleMapHidden: {
            type: Boolean,
            default: true
        },
        /**
         * @vuese
         * Switch between Sider experiences, routes and map.
         * <br>Default is map.
         */
        toggleMapProp: {
            type: Boolean,
            default: true
        },
        // Hide button next
        buttonNextHidden: {
            type: Boolean,
            default: false
        },
        // Disabled button next
        buttonNextDisabled: {
            type: Boolean,
            default: false
        },
        // Hide button report
        buttonReportHidden: {
            type: Boolean,
            default: false
        },
        // Disabled button report
        buttonReportDisabled: {
            type: Boolean,
            default: false
        },
        // Hide body and show "No hits message."
        showNoHitsMessage: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            /**
             * @vuese
             * Props for overlay when process is busy.
             */
            overlay: {
                opacity: 0.7,
                show: false
            },
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                getReport: `api/v1/let-s-go/report`,
            },
            /**
             * @vuese
             * Switch between Sider experiences, routes and map.
             * <br>Default is map.
             */
            toggleMap: true,
            slotProps: {},
            reportVisible: false,
            helpVisible: false,
            reportData: [],
            detailOn: false,
        }
    },
    mounted() {
        this.toggleMapDefault();
    },
    methods: {
        layoutFooterResize(e){
            this.$emit('eventLayoutResize', e);
        },
        toggleMapDefault() {
            let toggleMap = localStorage.getItem('toggleMap');
            switch (toggleMap) {
                case 'true':
                    // this.toggleMap = true;
                    this.toggleMap = true;
                    break;
                case 'false':
                    this.toggleMap = false;
                    break;
                default:
                    this.toggleMap = this.toggleMapProp;
            }
            // <--  -->
            this.$emit('eventToggleMap', this.toggleMap);
        },
        // <-- events -->
        eventPrevious() {
            // Trigger an event previous
            // @arg /
            this.$emit('eventPrevious');
        },
        eventToggleMap() {
            this.toggleMap = !this.toggleMap;
            // Trigger an event toggleMap
            // @arg /
            this.$emit('eventToggleMap', this.toggleMap);
        },
        eventReport() {
            if (!this.reportVisible) {
                this.loadReportFromBase();
            }
            this.reportVisible = !this.reportVisible;
        },
        eventNext() {
            // Trigger an event next
            // @arg /
            this.$emit('eventNext');
        },
        // <-- base -->
        /**
         * @vuese
         * Load report data from database
         */
        loadReportFromBase: function () {
            this.overlay.show = true;
            this.reportData = [];
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getReport + `/${this.$route.params.route}`, config)
                .then((response) => {
                    this.$set(this, 'reportData', response.data);
                    this.overlay.show = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.header_style {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.footer_style {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

/*-- report --*/
.report {
    position: absolute;
    top: 65px;
    left: 5%;
    right: 5%;
    bottom: 80px;
    background: url(../../../../../public/paper.gif);
    background-repeat: repeat;
    border-radius: 25px;
    /*opacity: 0.9;*/
    z-index: 1000;
}

.report_buttons {
    position: absolute;
    left: 5%;
    right: 5%;
    bottom: 20px;
}

.horizontal_dotted_line {
    border-bottom: 1px dotted black;
    height: 17px;
    width: 100%
}

.horizontal_summary_dotted_line {
    border-bottom: 1px dotted black;
    height: 23px;
    width: 100%
}

/*-- help --*/
.help {
    position: absolute;
    /*top: 65px;*/
    /*left: 5%;*/
    left: 0;
    /*right: 5%;*/
    /*margin: 0 auto !important;*/
    bottom: 63px;
    width: 100%;
    max-height: 30%;
    overflow-y: auto;
    background: #F9E355;
    /*background: url(../../../../../public/paper.gif);*/
    /*background-repeat: repeat;*/
    /*border-radius: 25px;*/
    /*opacity: 0.9;*/
    z-index: 1100;
}

</style>
